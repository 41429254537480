import http from "./axios/http";

export function getLobbyUrl(thirdPartyCode) {
  return http.get('/casino/honor/get_lobby_url?t=' + new Date().getTime(), {'thirdPartyCode':thirdPartyCode})
}

export function getGameList(vendor) {
  return http.get('/casino/honor2/get_game_list?t=' + new Date().getTime(), {'vendor':vendor})
}

export function getGameLancher(gameId,vendor,gtype) {
  return http.get('/casino/honor/get_game_lancher?t=' + new Date().getTime(), {'gameId':gameId,'vendor':vendor,'gtype':gtype})
}
export function getGameLancher2(gameId,vendor,gtype) {
  return http.get('/casino/honor2/get_game_lancher?t=' + new Date().getTime(), {'gameId':gameId,'vendor':vendor,'gtype':gtype})
}


export function getAccountBalance() {
  return http.get('/casino/honor/get_account_balance?t=' + new Date().getTime(), {})
}
export function getAccountBalance2() {
  return http.get('/casino/honor2/get_account_balance?t=' + new Date().getTime(), {})
}

export function addMemberCasinoCash(amount) {
  return http.get('/casino/honor/add_member_casino_cash?t=' + new Date().getTime(), {'amount':amount})
}
export function addMemberCasinoCash2(amount) {
  return http.get('/casino/honor2/add_member_casino_cash?t=' + new Date().getTime(), {'amount':amount})
}
export function subjectMemberCasinoCash() {
  return http.get('/casino/honor/subject_member_casino_cash?t=' + new Date().getTime())
}
export function subjectMemberCasinoCash2() {
  return http.get('/casino/honor2/subject_member_casino_cash?t=' + new Date().getTime())
}
export function getCasinoMaintaining() {
  return http.get('/casino/honor/get_casino_maintaining?t=' + new Date().getTime())
}
export function getHonorBetList(pageNum, pageSize) {
  return http.get('/casino/honor/get_honor_bet_list?t=' + new Date().getTime() + '&pageNum='+ pageNum  + '&pageSize=' + pageSize)
}

export function deleteAllHonorBet() {
  return http.get('/casino/honor/delete_all?t=' + new Date().getTime())
}
